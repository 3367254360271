import React from 'react'
import img from '../../images/orbitrek-swan.webp'
import {Helmet} from 'react-helmet'

import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const VacuBieznia = () => {
  return (
    <Layout>
      <Seo
        title='Swan Orbitrek IR z Lampami Kolagenowymi | Modelowanie Sylwetki'
        description='Urządzenie to łączy w sobie cztery komponenty, a połączenie ich w jednym
        urządzeniu powoduje, że podczas treningu działamy na całe ciało, gdzie
        jedno działanie potęguje drugie i uzupełnia kolejne.'
        url='https://modelowaniesylwetki-wawer.pl/strefa-fitness/swan-orbitrek-ir'
      />

      <div className='page-container'>
        <div className='page'>
          <div className='content-1'>
            <h1>Swan Orbitrek IR z Lampami Kolagenowymi</h1>
            <div className='section'>
              <div className='image-container'>
                <img
                  src={img}
                  width='660px'
                  height='440px'
                  alt='Swan Orbitrek zdjęcie'
                ></img>
              </div>
              <div className='text'>
                <p>
                  Urządzenie to łączy w sobie cztery komponenty, a połączenie ich w jednym
                  urządzeniu powoduje, że podczas treningu działamy na całe ciało, gdzie
                  jedno działanie potęguje drugie i uzupełnia kolejne. SWAN ORBITREK IR Z
                  KOLAGENEM to Orbitrek pionowy umieszczony w specjalnej kapsule wykonanej
                  z polietylenu, w obudowie którego zastosowano dodatkowo lampy z
                  podczerwienią IR, lampy kolagenowe i lampy UVB, które podnoszą ilość
                  witaminy D w naszym organizmie. Trening na tym urządzeniu ma szereg
                  zalet: spala tkankę tłuszczową, poprawia wydolność, wzmacnia wszystkie
                  najważniejsze partie mięśniowe, nie obciąża stawów i minimalizuje ryzyko
                  kontuzji. Trening jest zalecany każdemu niezależnie od wieku i stopnia
                  sprawności fizycznej. Jednoczesne działanie głęboko penetrująco –
                  oczyszczające, odbudowujące, poprawiające elastyczność i jędrność skóry,
                  daje efekty spektakularne i długotrwałe.
                </p>
                <div className='cennik'>
                  <span>CENNIK</span>
                  <h2>ORBITREK Z LAMPAMI KOLAGENOWYMI</h2>
                  <ul>
                    <li>
                      <p>ORBITREK Z LAMPAMI KOLAGENOWYMI - POJEDYNCZY ZABIEG</p>
                      <span className='prices'>30 MIN | 40 zł</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Fade left>
            <Link to='/kontakt'>
              <button>Umów Wizytę</button>
            </Link>
          </Fade>
        </div>
      </div>
    </Layout>
  )
}

export default VacuBieznia
